<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irrigation_config.mouza') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_division.division')"
                label-for="division_id"
                >
                <b-form-select
                    plain
                    v-model="search.division_id"
                    :options="divisionList"
                    id="division_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_district.district')"
                label-for="district_id"
                >
                <b-form-select
                    plain
                    v-model="search.district_id"
                    :options="districtList"
                    id="district_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_upazilla.upazilla')"
                label-for="upazilla_id"
                >
                <b-form-select
                    plain
                    v-model="search.upazilla_id"
                    :options="upazilaList"
                    id="upazilla_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_union.union')"
                label-for="union_id"
                >
                <b-form-select
                    plain
                    v-model="search.union_id"
                    :options="unionList"
                    id="union_id"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('irrigation_config.mouza')"
                label-for="mouza_name"
                >
                <b-form-input
                    id="mouza_name"
                    v-model="search.mouza_name"
                    placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="text-right">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.mouza') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">{{ data.item.district_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(union_name)="data">
                      <span class="capitalize">{{ data.item.union_name }}</span>
                    </template>
                    <template v-slot:cell(mouza_name)="data">
                      <span class="capitalize">{{ data.item.mouza_name }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge" :class="data.item.status ? 'badge-danger' : 'badge-success'">{{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './FormV'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { mouzaListApi, mouzaToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  name: 'List',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        mouza_name: '',
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0
      },
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('irrigation_config.mouza') + ' ' + this.$t('globalTrans.entry') : this.$t('irrigation_config.mouza') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro_district.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('org_pro_union.union'), class: 'text-center' },
          { label: this.$t('irrigation_config.mouza'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'union_name_bn' },
          { key: 'mouza_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'union_name' },
          { key: 'mouza_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, mouzaToggleStatus, item, 'irrigation', 'mouzaList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, mouzaListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
      //
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const tmpDistrict = this.$store.state.commonObj.districtList.find(el => el.value === item.district_id)
        const tmpUpazila = this.$store.state.commonObj.upazilaList.find(el => el.value === item.upazilla_id)
        const tmpUnion = this.$store.state.commonObj.unionList.find(el => el.value === item.union_id)

        const newData = {
          district_name: tmpDistrict !== undefined ? tmpDistrict.text_en : '',
          district_name_bn: tmpDistrict !== undefined ? tmpDistrict.text_bn : '',
          upazilla_name: tmpUpazila !== undefined ? tmpUpazila.text_en : '',
          upazilla_name_bn: tmpUpazila !== undefined ? tmpUpazila.text_bn : '',
          union_name: tmpUnion !== undefined ? tmpUnion.text_en : '',
          union_name_bn: tmpUnion !== undefined ? tmpUnion.text_bn : ''
        }

        return Object.assign({}, item, newData)
      })
      return listData
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === parseInt(upazilaId))
      }
      return unionList
    }
  }
}
</script>
